import React from "react";
import { Colors } from "../../../utils/constants/colors";
import { LearnIcons } from "../../../assets/images";
import Button from "../../button";
import { useUser } from "../../../redux/useStore";



export default function QuizModal(props: any) {


    const quizData = props.quiz

    const user = useUser()

    const [currentQuestion, setCurrentQuestion] = React.useState(0);
    const [selectedOption, setSelectedOption] = React.useState(null);

    const [showExplanation, setShowExplanation] = React.useState(false);


    const onCloseModal = () => {
        if (props.onClose) {
            props.onClose();
        }
    }

    const onNext = () => {
        if (currentQuestion <= quizData.length - 1) {
            setCurrentQuestion(currentQuestion + 1)
            setSelectedOption(null)
            setShowExplanation(false)
        } else {
            onCloseModal()
        }
    }


    return (
        <div
            onClick={onCloseModal}
            style={{
                display: "flex", flexDirection: "column", alignItems: "center",
                width: "100%", height: "100%", justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.5)", position: "fixed", top: 0, left: 0, zIndex: 1000,
            }}>
            <div
                onClick={(e) => e.stopPropagation()}
                style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "white", padding: 20, borderRadius: 10, maxWidth: '50%', minWidth: '50%' }}>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', backgroundColor: "white", width: '100%' }}>
                    <span></span>
                    <img
                        onClick={onCloseModal}
                        src={LearnIcons.Cross} style={{ width: 25, height: 25, cursor: 'pointer' }} alt="quiz" />
                </div>

                <div style={{
                    display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "white", padding: 10,
                    borderBottom: '1px solid #e0e0e0', width: '90%'
                }}>

                    {
                        currentQuestion < quizData.length ?
                            <h3 style={{ margin: 5, color: Colors.primary }}>
                                <span style={{ color: 'black', marginRight: 10 }}>{currentQuestion + 1}/{quizData.length}</span>
                                Chanakya Challange !</h3>
                            :
                            <h3 style={{ margin: 5, color: Colors.primary }}>
                                Challange Completed !</h3>

                    }

                </div>
                {
                    (currentQuestion < quizData.length) ?
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center', backgroundColor: "white", padding: 20, width: '100%' }}>
                            <h3>{quizData[currentQuestion].question}</h3>
                            {
                                showExplanation === false ?
                                    <>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: 'column',
                                            alignItems: "flex-start", justifyContent: 'center', backgroundColor: "white", padding: 10,
                                        }}>
                                            {
                                                quizData[currentQuestion].options.map((option: any) => (
                                                    <div key={option}
                                                        onClick={() => setSelectedOption(option)}
                                                        style={{
                                                            cursor: "pointer", margin: 10,
                                                            flexDirection: 'row',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}>
                                                        {
                                                            selectedOption === option ?
                                                                selectedOption === quizData[currentQuestion].answer ?
                                                                    <img src={LearnIcons.CorrectChecked} style={{ width: 20, height: 20, marginRight: 10 }} alt="correct" />
                                                                    :
                                                                    <img src={LearnIcons.WrongChecked} style={{ width: 20, height: 20, marginRight: 10 }} alt="wrong" />
                                                                :
                                                                <img src={LearnIcons.Circle} style={{ width: 20, height: 20, marginRight: 10 }} alt="circle" />
                                                        }
                                                        {
                                                            selectedOption === option ?
                                                                selectedOption === quizData[currentQuestion].answer ?
                                                                    <span style={{ fontSize: 16, color: Colors.success }}>{option}</span>
                                                                    :
                                                                    <span style={{ fontSize: 16, color: Colors.error }}>{option}</span>
                                                                :
                                                                selectedOption !== null && quizData[currentQuestion].answer === option ?
                                                                    <span style={{ fontSize: 16, color: Colors.success }}>{option}</span>
                                                                    :
                                                                    <span style={{ fontSize: 16, color: 'gray' }}>{option}</span>
                                                        }

                                                    </div>
                                                ))
                                            }
                                        </div>

                                        {
                                            selectedOption !== null && quizData[currentQuestion].answer !== selectedOption &&
                                            <span
                                                onClick={() => setShowExplanation(true)}
                                                style={{
                                                    color: Colors.primary,
                                                    cursor: "pointer",
                                                    margin: 10,
                                                    textDecoration: 'underline',
                                                    fontWeight: 'bold',
                                                    fontSize: 16
                                                }}>Explain Why !</span>

                                        }
                                    </>
                                    :
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        width: '70%',
                                        alignItems: "center", justifyContent: 'center', backgroundColor: "white", padding: 10,
                                    }}>
                                        {/* <h3 style={{color:Colors.primary}}>Let's Understand !</h3> */}
                                        <span style={{ fontSize: 16, fontWeight: '500', textAlign: 'center', color: Colors.success }}>{quizData[currentQuestion].answer}</span>
                                        <p
                                            style={{
                                                textAlign: 'center',
                                                color: 'gray',
                                                fontSize: 16
                                            }}
                                        >{quizData[currentQuestion].explanation}</p>
                                    </div>
                            }



                            <Button disabled={selectedOption == null} onClick={onNext}
                                title={currentQuestion <= quizData.length - 1 ? 'Next' : 'Finish'}
                                style={{ width: '100px', paddingTop: 10, paddingBottom: 10, borderRadius: '5px' }} />
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center', backgroundColor: "white", padding: 20, width: '100%' }}>
                            <h3 style={{ marginBottom: 0 }}>🎉 Great Work {user?.firstName} !</h3>
                            <div style={{
                                display: "flex",
                                flexDirection: 'column',
                                width: '70%',
                                alignItems: "center", justifyContent: 'center', backgroundColor: "white", padding: 10,
                            }}>
                                <p
                                    style={{
                                        textAlign: 'center',
                                        color: 'gray',
                                        fontSize: 16
                                    }}
                                >This quiz was aimed to make you understand the topic better, and analyse how much you understood the details. If you still have any doubts or confusions, you can ask Chanakya Bot for help and it will be happy to assist you in your learning journey !</p>
                            </div>
                            <Button onClick={onCloseModal} title={'Finish'} style={{ width: '100px', paddingTop: 10, paddingBottom: 10, borderRadius: '5px' }} />
                        </div>
                }

            </div>
        </div>
    );
}