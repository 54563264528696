import React, {useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './index.css';
import { AppSvg } from '../../assets/svg';

interface StartCourseModalProps {
  open: boolean;
  onClose: () => void;
}

const StartCourseModal: React.FC<StartCourseModalProps> = ({ open, onClose }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        navigate(`/learn/course/customize/${id}`);
      }, 2000);
      
      return () => clearTimeout(timer);
    }
  }, [open, id, navigate]);

  if (!open) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2 className="modal-title">🎉 Congratulations!</h2>
        <p className="modal-description">
          Your Awesome learning Journey begins here, complete this course to win exciting rewards and invaluable knowledge.
        </p>
        <div className="rewards-section">
          <h3 className="rewards-title">Rewards</h3>
          <div className="rewards-container">
            <div className="reward-item">
              <span className="reward-icon">
              <img src={AppSvg.Rewards} alt="send" style={{ width: 70, height: 70 }} />
              </span>
              <span className="reward-name">
                <strong>Python Hunter</strong>
              </span>
            </div>
            <div className="reward-item">
              <span className="reward-icon">
              <img src={AppSvg.LevelUp} alt="send" style={{ width: 70, height: 70 }} />
              </span>
              <span className="reward-name">
                <strong>Level Up</strong>
              </span>
            </div>
            <div className="reward-item">
              <span className="reward-icon">
              <img src={AppSvg.Certificate} alt="send" style={{ width: 70, height: 70 }} />
              </span>
              <span className="reward-name">
                <strong>Certificate</strong>
              </span>
            </div>
          </div>
        </div>
        <div className="loading-container">
          <p className="loading-text">
            <span className="spinner"></span>
            Generating your perfect course...
          </p>
        </div>
      </div>
    </div>
  );
};

export default StartCourseModal;
