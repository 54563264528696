import React from "react";
import { AppSvg } from "../../../assets/svg";
import './styles.css';
import { DrawerIcon } from "../../../assets/images";
import { useNavigate } from "react-router-dom";


export default function ChanakyaLearnHeader() {


    const navigate = useNavigate()

    const onLogoPress = () => {
        navigate('/learn/course/list')
    }


    return (
        <div className="chanakya-learn-header">
            <img src={AppSvg.chanakyaLogo} alt="chanakya logo" style={{ width: 150, height: 50, marginLeft: 10,cursor:'pointer' }} onClick={onLogoPress} />
            <img src={DrawerIcon.user} alt="user" style={{ width: 50, height: 50, marginRight: 10 }} />
        </div>
    )
}