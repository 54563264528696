import { useAxios } from "../useAxios";
import { chanakyaLearnCourses } from "../../config/envConfig";

export const useChanakyaLearn = () => {
  const axios = useAxios();

  const chanakyaCourses = async () => {
    try {
      const response = await axios.get(chanakyaLearnCourses);
      console.log("Courses data:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching subscription details:", error);
      throw error;
    }
  };

  return {
    chanakyaCourses,
  };
};
