import { createSlice } from "@reduxjs/toolkit";
import { LocalUser } from "../utils/interface/localUser";

const initialState = {
  user: null as LocalUser | null,
  isLoggedIn: false as boolean,
};

interface actionInterface {
  type: string;
  payload: LocalUser | null;
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setActiveUser: (state, action: actionInterface) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    setLogoutUser: (state) => {
      state.user = null;
      state.isLoggedIn = false;
    },
  },
});

export const { setActiveUser, setLogoutUser } = userSlice.actions;

export const selectUser = (state: any) => state.user.user;
export const selectIsLoggedIn = (state: any) => state.user.isLoggedIn;

export default userSlice.reducer;
