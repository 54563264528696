
import { useState } from 'react';
import './styles.css';
import { Button, LoadingModal } from '../../../../components';
import { useAxios } from '../../../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import errorHandler from '../../../../utils/common/errHandler';
import { useDispatch } from 'react-redux';
import { setLogoutUser } from '../../../../redux/userSlice';


export default function DataControl() {

    const axios = useAxios()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [confirmText, setConfirmText] = useState('')
    const [isLoading, setIsLoading] = useState(false)


    const onDeleteAccount = () => {
        setIsLoading(true)
        axios.delete(`auth/delete-user/`)
            .then(res => {
                setIsLoading(false)
                dispatch(setLogoutUser())
                navigate('/login')
            })
            .catch(err => {
                setIsLoading(false)
                console.error(err)
                errorHandler('Delete Account', err)
            })
    }

    

    return (
        <div className='data-control-section'>

            {
                isLoading &&
                <LoadingModal modalVisible={true} />
            }

            <div className='data-section'>
                <h3 className='data-title'>Delete Account</h3>
                <p >Type word DELETE in all caps to delete your account. This action is irreversible.</p>
                <input className='data-input' placeholder='Enter confirmation' type="text" value={confirmText} onChange={(e) => setConfirmText(e.target.value)} />
                <Button
                    title='Delete Account'
                    style={{ backgroundColor: confirmText !== 'DELETE' ? 'gray' : 'red', color: 'white', width: '50%', marginTop: 20 }}
                    onClick={onDeleteAccount}
                    disabled={confirmText !== 'DELETE'}

                />
            </div>
        </div>
    )
}