import React from "react";
import './styles.css';
import { useAxios } from "../../../hooks/useAxios";
// import axios from "axios";
import { LoadingModal } from "../../../components";

export default function ErrorSimulator() {

    const axios = useAxios();

    

    const [isLoading, setIsLoading] = React.useState(false);
    const [result, setResult] = React.useState<any>(null);

    const simCases = [
        // { id: 1, name: "Bad Request", code: 400, description: "The request could not be understood by the server due to malformed syntax. The client SHOULD NOT repeat the request without modifications." },
        { id: 2, name: "Session Error", code: 401, description: "The request requires user authentication. The response MUST include a WWW-Authenticate header field containing a challenge applicable to the requested resource." },
        // { id: 3, name: "Forbidden", code: 403, description: "The server understood the request, but is refusing to fulfill it. Authorization will not help and the request SHOULD NOT be repeated." },
        { id: 4, name: "Not Found", code: 404, description: "The server has not found anything matching the Request-URI. No indication is given of whether the condition is temporary or permanent." },
        { id: 8, name: "Conflict", code: 409, description: "The request could not be completed due to a conflict with the current state of the target resource." },
        { id: 5, name: "Limit Error", code: 429, description: "The user has sent too many requests in a given amount of time. Intended for use with rate limiting schemes." },
        { id: 6, name: "Server Error", code: 500, description: "The server encountered an unexpected condition which prevented it from fulfilling the request." },
        // { id: 7, name: "Service Unavailable", code: 503, description: "The server is currently unable to handle the request due to a temporary overloading or maintenance of the server." }
    ]

    const simulateApi = async (code: number) => {
        setIsLoading(true);
        await axios.get(`https://uat.aichanakya.in/chanakya/exception/?status_code=${code}`)
            .then((res) => {
                console.log(JSON.stringify(res.data));
                setResult({
                    result : res.data,
                    type: "success"
                });
                setIsLoading(false);
            }).catch((error) => {
                console.error(error.response.data);
                setResult(error.response.data)
                setIsLoading(false);
            })
    }

    return (
        <div className="error-simulator">
            {
                isLoading && <LoadingModal modalVisible={isLoading} />
            }
            <h1>Api Error Simluator</h1>
            <div className="sim-wrapper">
                <div className="sim-input-section">
                    <h2>Api Section</h2>
                    <div className="sim-input-holder">
                        {
                            simCases.map((simCase) => {
                                return (
                                    <div className="sim-case" key={simCase.id}>
                                        <div className="sim-case-desc">
                                            <h3>{simCase.name} ( {simCase.code} )</h3>
                                            <p>{simCase.description}</p>
                                        </div>
                                        <button className="sim-try" onClick={() => simulateApi(simCase.code)}>Simulate Api</button>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
                <div className="sim-output-section">
                    <h2>Output Section</h2>
                    <div className="sim-output-holder">
                        <div className="sim-output">
                            <h3>Result - </h3>
                            <p>You can see the result of the api call here.</p>
                            <pre style={{ whiteSpace: "pre-wrap" }}>
                                {JSON.stringify(result)}
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}