import axios from "axios";
import { ApiHead } from "../config/envConfig";
import { useUser } from "../redux/useStore";
import { useDispatch } from "react-redux";
import { setLogoutUser } from "../redux/userSlice";

export const useAxios = () => {
  const user = useUser();
  const dispatch = useDispatch();

  const axiosConfig = {
    baseURL: ApiHead,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const axiosInstance = axios.create(axiosConfig);

  const interceptor = axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response?.status === 401) {
        axiosInstance.interceptors.response.eject(interceptor);
        dispatch(setLogoutUser());
        window.location.href = "/login";
      } else {
        // errorHandler(error)
      }

      return Promise.reject(error);
    }
  );
  return axiosInstance;
};
