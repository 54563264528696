
import { useState } from 'react';
import './styles.css';
import { Button, LoadingModal } from '../../../../components';
import moment from 'moment';
import { useUser } from '../../../../redux/useStore';
import { useAxios } from '../../../../hooks/useAxios';
import { useDispatch } from 'react-redux';
import { setActiveUser } from '../../../../redux/userSlice';
import { LocalUser } from '../../../../utils/interface/localUser';
import errorHandler from '../../../../utils/common/errHandler';



export default function Profile(props: any) {

    const localUser = useUser()
    const dispatch = useDispatch()

    const axios = useAxios()

    const [user, setUser] = useState(props.user || null)
    const [mobileNumber, setMobileNumber] = useState(user?.mobile_no || '');
    const [dob, setDob] = useState<any>(user?.dob || null);
    const [bio, setBio] = useState(user?.bio || "");
    const [interest, setInterest] = useState(user?.interest || "");
    const [mode, setMode] = useState('view')

    const [isLoading, setIsLoading] = useState(false)


    const onSubmit = async () => {
        // const mobileRegex = /^[6-9]\d{9}$/;

        let data: {
            preferred_language: string;
            mobile_no?: string;
            date_of_birth?: string;
            profile_bio?: string;
            task_interests?: string;
        } = {
            preferred_language: "English",
        }

        // Positive Checking
        if (mobileNumber !== "") {
            // if(!mobileRegex.test(mobileNumber)){
            //     alert('Invalid Mobile Number')
            //     return
            // }
            data.mobile_no = mobileNumber
        }

        if (dob) {
            data.date_of_birth = moment(dob).format("YYYY-MM-DD")
        }

        if (bio !== "") {
            data.profile_bio = bio
        }

        if (interest !== "") {
            data.task_interests = interest
        }
        console.warn(data)
        setIsLoading(true)
        await axios.post(`auth/update-user/`, data)
            .then(res => {
                console.log(res.data)
                setIsLoading(false)
                let newUser = {
                    ...localUser,
                    ...data
                } as LocalUser
                setUser(newUser)
                dispatch(setActiveUser(newUser))
                setMode('view')
            })
            .catch(err => {
                setIsLoading(false)
                console.error(err)
                errorHandler('Update Profile', err)
            })



    }

    const switchMode = () => {
        if (mode === 'view') {
            setMode('edit')
        } else {
            setMode('view')
        }
    }


    return (
        <div className='profile-section'>

            {
                isLoading &&
                <LoadingModal modalVisible={true} />
            }

            <div className='data-section'>
                <h3 className='data-title'>Name</h3>
                <span className='data-label'>{user?.first_name}</span>
            </div>
            <div className='data-section'>
                <h3 className='data-title'>Email</h3>
                <span className='data-label'>{user?.email}</span>
            </div>
            <div className='data-section'>
                <h3 className='data-title'>Mobile No.</h3>
                {
                    mode === "view" ?
                        <span className='data-label'>{user.mobile_no}</span>
                        :
                        <input className='data-input' placeholder='Enter your mobile number.' type="number"  value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                }

            </div>

            <div className='data-section'>
                <h3 className='data-title'>Date of Birth</h3>
                {
                    mode === "view" ?
                        <span className='data-label'>{user.date_of_birth || 'Select Date'}</span>
                        :
                        <input className='data-input' placeholder='Select date of birth' type="date" value={dob || ""} onChange={(e) => setDob(e.target.value)} />
                }
            </div>

            <div className='data-section'>
                <h3 className='data-title'>Bio</h3>
                {
                    mode === "view" ?
                        <span className='data-label'>{user.bio || 'Write something awesome !'}</span>
                        :
                        <textarea className='data-input' placeholder='Enter your bio' value={bio} onChange={(e) => setBio(e.target.value)} />
                }
            </div>


            <div className='data-section'>
                <h3 className='data-title'>Interest</h3>
                {
                    mode === "view" ?
                        <span className='data-label'>{user.interest || 'No Intrests'}</span>
                        :
                        <input className='data-input' placeholder='Enter your interest' type="text" value={interest} onChange={(e) => setInterest(e.target.value)} />
                }
            </div>

            <div style={{ width: '50%', flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                <Button onClick={switchMode} title={mode === 'edit' ? "Cancel" : 'Edit'} className='edit' />
                <Button onClick={onSubmit} title='Save' />


            </div>

        </div>
    )
}