import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.css';
import { AppSvg } from '../../../../assets/svg';
import { useUserApi } from '../../../../hooks/user';
import { useUser } from '../../../../redux/useStore';
import { setActiveUser } from '../../../../redux/userSlice';
import { useDispatch } from 'react-redux';
import { useChanakyaLearn } from '../../../../hooks/chanakyaLearn';
import { Interest, LocalUser } from '../../../../utils/interface/localUser';


interface Course {
  course_name: string;
}

const LearnScreen: React.FC = () => {

  const { chanakyaCourses } = useChanakyaLearn();

  const location = useLocation();
  const user = useUser();
  const navigate = useNavigate();


  useEffect(() => {
    if (user) {
      if ((user.skills?.length ?? 0) > 0 && (user.interests?.length ?? 0) > 0) {
        navigate('/learn/course/list', { replace: true });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const { updateChanakyaLearnUserInfo } = useUserApi();

  const isOnboarding = location.pathname.includes('/onboarding');
  const [onboardingStep, setOnboardingStep] = useState<'skills' | 'interests'>('skills');
  const [interests, setInterests] = useState<Interest[]>([]);
  const [selectedInterest, setSelectedInterest] = useState('');
  const [skillsSummary, setSkillsSummary] = useState('');
  const dispatch = useDispatch();
  const [courses, setCourses] = useState<Course[]>([]);


  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const data = await chanakyaCourses();
        const formattedData = data.map((course: any) => ({
          course_name: course.course_name,
        }));
        setCourses(formattedData);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleSkillsSummaryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSkillsSummary(e.target.value);
  };

  const addInterest = () => {
    if (selectedInterest && !interests.some(i => i.language === selectedInterest)) {
      setInterests([...interests, { language: selectedInterest, level: 'NA' }]);
      setSelectedInterest('');
    }
  };

  const removeInterest = (interestToRemove: string) => {
    setInterests(interests.filter(interest => interest.language !== interestToRemove));
  };

  const handleNext = async () => {
    if (skillsSummary || interests.length > 0) {
      const data: Partial<{ skills: string; interests: { language: string; level: string }[]; profileBio: string }> = {};
      
      if (skillsSummary) {
        data.profileBio = skillsSummary;
      }
      
      if (interests.length > 0) {
        data.interests = interests.map(interest => ({
          language: interest.language,
          level: interest.level
        }));
      }

      const updatedUser = await updateChanakyaLearnUserInfo(data);

      if (onboardingStep === 'skills') {
        if (skillsSummary.trim()) {
          if (updatedUser) {
            dispatch(setActiveUser({
              ...user,
              profileBio: skillsSummary
            } as LocalUser));
          }
          setOnboardingStep('interests');
        } else {
          console.log('Please add your skills before continuing.');
        }
      } else {
        if (skillsSummary.trim() && interests.length > 0) {
          console.log('Skills:', skillsSummary);

          const changedInterests = interests.map(interest => ({
            language: interest.language,
            level: interest.level
          } as Interest));

          if (updatedUser) {
            dispatch(setActiveUser({
              ...user,
              interests: changedInterests
            } as LocalUser));
          }

          navigate('/learn/course/list', { replace: true });
          // if both are filled, navigating to the root
          // navigate('/');
        } else {
          console.log('Please fill both skills and interests before continuing.');
        }
      }
    } else {
      console.log('Please add at least one skill or interest before continuing.');
    }
  };

  const handleGetStarted = () => {
    navigate('/learn/onboarding');
  };

  const handleInterestSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedInterest(e.target.value);
  };

  return (
    <>
      <header className="learn-header">
        <img src={AppSvg.chanakyaLogo} alt="logo" className="header-logo" />
      </header>
      <div className="learn-container">
        <div className="learn-content-wrapper">
          {!isOnboarding ? (
            <div className="learn-text-container">
              <p className='learn-text'>Welcome To</p>
              <h1 className="learn-content">Learn With Chanakya</h1>
              <button className="continue-button" onClick={handleGetStarted}>
                <strong>Get Started!</strong>
              </button>
            </div>
          ) : onboardingStep === 'skills' ? (
            <div className="learn-text-container">
              <p className="learn-text">Let's get to know you!</p>
              <h1 className="learn-content">Tell us about your Skills!</h1>
              <div className="interests-input-container">
                <textarea
                  className="skills-textarea"
                  placeholder="Ex -  I have this 3 year exp in react js and I am a good developer"
                  value={skillsSummary}
                  onChange={handleSkillsSummaryChange}
                  rows={4}
                />
              </div>
              <button className="continue-button" onClick={handleNext}>
                <strong>Next</strong>
              </button>
            </div>
            
          ) : (
            <div className="learn-text-container">
              <p className="learn-text">Let's get to know you!</p>
              <h1 className="learn-content">How about your Interests?</h1>
              <div className="interests-input-container">
                <select
                  className="interests-select"
                  value={selectedInterest}
                  onChange={handleInterestSelectChange}
                >
                  <option value="" disabled>Select an interest</option>
                  {courses.map((course, index) => (
                    <option key={index} value={course.course_name}>
                      {course.course_name}
                    </option>
                  ))}
                </select>
                <button className="add-interest-btn" onClick={addInterest} disabled={!selectedInterest}>
                  Add
                </button>
              </div>
              <div className="interests-list">
                {interests.map((interest, index) => (
                  <span key={index} className="interest-tag">
                    {interest.language}
                    <button
                      className="remove-interest"
                      onClick={() => removeInterest(interest.language)}
                    >
                      &#10005;
                    </button>
                  </span>
                ))}
              </div>
              <button className="continue-button" onClick={handleNext}>
                <strong>Continue</strong>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LearnScreen;
