import React, { useState } from 'react';
import './index.css';
import { AppSvg } from '../../assets/svg';

interface ReportBoxModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const reportTypes = [
  { id: 1, title: 'Inaccurate' },
  { id: 2, title: 'Outdated' },
  { id: 3, title: 'Too Short' },
  { id: 4, title: 'Harmful' },
  { id: 5, title: 'Not Helpful' },
  { id: 6, title: 'Other' }
];

const ReportBoxModal: React.FC<ReportBoxModalProps> = ({ isOpen, onClose }) => {
  const [selectedReport, setSelectedReport] = useState<number | null>(null);
  const [description, setDescription] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  if (!isOpen) return null;

  const onSubmit = () => {
    if (selectedReport !== null) {
      setIsSubmitted(true);
      setTimeout(() => {
        setIsSubmitted(false);
        onClose();
      }, 2000);
    }
  };

  const onSelectReport = (id: number) => {
    setSelectedReport(id);
  };

  return (
    <div className="report-modal-overlay">
      <div className="report-modal-content">
        <div className="report-sheet-parent">
          <div className="report-sheet-header">
            <h2 className="header-text">Help Us Improve</h2>
            <button className="report-close-button" onClick={onClose}>
            <img src={AppSvg.cross} alt="" />
          
            </button>
          </div>

          <div className="report-types-container">
            {reportTypes.map((report) => (
              <button
                key={report.id}
                className={`report-type ${selectedReport === report.id ? 'selected' : ''}`}
                onClick={() => onSelectReport(report.id)}
              >
                {report.title}
              </button>
            ))}
          </div>

          <textarea
            placeholder="Describe the issue (Optional)"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={`report-textarea ${description ? 'has-content' : ''}`}
            rows={4}
          />

          <button 
            className="submit-button"
            onClick={onSubmit}
            disabled={selectedReport === null}
          >
            Submit
          </button>

          {isSubmitted && (
            <div className="success-message">
              <h3>Success!</h3>
              <p>Thank you for your feedback. We appreciate your help in improving our service.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportBoxModal;

