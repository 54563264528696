import { useAxios } from "../useAxios";
import { useState } from "react";
import { userSubscriptionDetail } from "../../config/envConfig";
import { updateChanakyaLearnUserApi } from "../../config/envConfig";

export interface IUserInfo {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  profilePhoto: string;
  mobileNo: string;
  profileBio: string;
  taskInterests: string;
  preferredLanguage: string;
  dateOfBirth: string;
  token: string;
  isPro: boolean;
}

export const useUserApi = () => {
  const axios = useAxios();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const updateUser = async (requestParams: any) => {
    setLoading(true);
    try {
      const response = await axios.post(`auth/update-user/`, requestParams);
      console.log("Updated Usern API Res ::::: ", response);
      return response.data;
    } catch (error: any) {
      // errorHandler(error?.message, "Update User");
      if (error.response && error.response.status === 400) {
        console.error("Bad request:", error);
        setError(new Error("Bad request"));
      } else {
        console.error("Error updating user:", error);
        setError(new Error("Error updating user"));
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateChanakyaLearnUserInfo = async (requestParams: any) => {
    setLoading(true);
    try {
      const response = await axios.post(
        updateChanakyaLearnUserApi,
        requestParams
      );
      console.log("Updated Chanakya Learn User API Res:", response);
      return response.data;
    } catch (error: any) {
      if (error.response?.status === 400) {
        console.error("Bad request:", error);
        setError(new Error("Bad request"));
      } else {
        console.error("Error updating Chanakya Learn user:", error);
        setError(new Error("Error updating Chanakya Learn user"));
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getUserInfo = async (): Promise<IUserInfo | null> => {
    setLoading(true);
    try {
      const response = await axios.get(`auth/get-user/`);
      // console.log(response);
      const data = response.data;

      if (!data) {
        // errorHandler("No data found in response", "Get User");
        throw new Error("No data found in response");
      }
      return {
        email: data?.email || "",
        firstName: data?.first_name || "",
        id: data?.id || 0,
        lastName: data?.last_name || "",
        mobileNo: data?.mobile_no || "",
        profileBio: data?.profile_bio || "",
        profilePhoto: data?.profile_photo || "",
        taskInterests: data?.task_interests || "",
        isActive: data?.is_active || false,
        preferredLanguage: data?.preferred_language || "",
        dateOfBirth: data?.date_of_birth || "",
        token: data?.token || "",
        isPro: data?.is_pro || false,
      };
    } catch (error: any) {
      console.error("Error fetching user info:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(`auth/delete-user/`);
      setLoading(false);
      console.log("delete user api", response);
      return response.data;
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.status === 400) {
        console.error("Bad request:", error);
        setError(new Error("Bad request"));
      } else {
        console.error("Error deleting user:", error);
        setError(new Error("Error deleting user"));
      }
    }
  };

  const userSubscriptionDetails = async () => {
    try {
      const response = await axios.get(userSubscriptionDetail);
      console.log("Subscription data:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching subscription details:", error);
      throw error;
    }
  };

  return {
    updateUser,
    deleteUser,
    getUserInfo,
    error,
    loading,
    userSubscriptionDetails,
    updateChanakyaLearnUserInfo,
  };
};
