
const Strings = {
    errors: {
        guestLimitReached: "You have reached the maximum conversion limit. Please sign up to continue.",
        userLimitReached: "You have reached the maximum user limit. Please upgrade your plan to continue.",
        proLimitReached: "You have reached the maximum conversion limit. Please wait for the next cycle to continue.",
        networkError: "Oops something went wrong. Please check your internet connection and try again.",
        serverEror: "Oops something went wrong. Please try again later.",
        sessionExpired: "Your session has expired. Please login again.",
        notFound: "The requested resource was not found.",
        alreadyExits: "The requested resource already exists.",
        unknownError: "Oops something went wrong. Please try again later.",
    }
}

export default Strings;