const AppRoutes = {
  auth: {
    tag: 'auth',
    login: '/login',
  },
  app: {
    tag: 'app',
    chatScreen: '/chat',
    profile: '/profile',
  }
};

export default AppRoutes;
