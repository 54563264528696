import React, { useEffect } from "react";
import { AppSvg } from "../../../../../assets/svg";
import { Button } from "../../../../../components";
import './styles.css';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAxios } from "../../../../../hooks/useAxios";
import { ApiCollection } from "../../../../../config/envConfig";;


export default function CourseDetailQuestionare() {

    const navigate = useNavigate()
    const axios = useAxios()

    const [searchParams] = useSearchParams();

    const language = searchParams.get('language');
    const level = searchParams.get('level');

    const [questions, setQuestions] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [pointer, setPointer] = React.useState<number>(0);
    const [answers, setAnswers] = React.useState<any[]>([]);
    const [selectedOption, setSelectedOption] = React.useState<string>('');

    useEffect(() => {
        getQuestions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getQuestions = async () => {
        setIsLoading(true)
        await axios.get(`${ApiCollection.chanakyaGuru.getQuestionare}?language=${language}&level=${level}`)
            .then((res: any) => {
                if (res.status === 200) {
                    console.log('Questions fetched', res.data.data)
                    setQuestions(res.data.data)
                    // setQuestions(res.data.questions)
                    setIsLoading(false)
                } else {
                    console.log('Error fetching questions')
                    setIsLoading(false)
                }
            })
            .catch((err: any) => {
                console.log('Error fetching questions', err)
                setIsLoading(false)
            })
    }




    const onNextClick = () => {
        if(selectedOption === ''){
            alert('Please select an option')
            return
        }
        setSelectedOption('')

        let tempAnswers = [...answers]
        let newAnswer = {
            key: questions[pointer].id,
            value: selectedOption
        }
        tempAnswers.push(newAnswer)
        setAnswers(tempAnswers)

        if (pointer < questions.length - 1) {
            setPointer(pointer + 1)
        } else {
            console.log('Answers', answers);
            navigate(-1)
            axios.post(ApiCollection.chanakyaGuru.postSkillAnalytics, {
                "onboarding_answer": answers
            })


        }
    }




    return (
        !isLoading ?
            <div className="course-list-screen">

                <div className="course-list-header">
                    <img src={AppSvg.chanakyaLogo} alt="logo" style={{ width: "183px", height: "25px" }} />
                </div>
                <div className="course-list-body">
                    <div className="course-list-heading-holder">
                        <span>Before we begin</span>
                        <h2>Let's get to know you !</h2>
                    </div>
                    <div className="course-list-item-holder">
                        {questions.length > 0 ?
                            <div className="questionare-holder">
                                <div className="questionare">
                                    <h4>{pointer + 1}. {questions[pointer].question}</h4>
                                    <div className="options-holder">
                                        {questions[pointer].option.map((option: any, index: number) => (
                                            <div key={index} className={selectedOption === String(Object.entries(option)[0][1]) ? "option selected" : "option"} onClick={() => setSelectedOption(String(Object.entries(option)[0][1]))}>
                                                <span>{String(Object.entries(option)[0][1])}</span>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <span style={{ fontSize: '20px' }}>No questions found</span>
                            </div>
                        }
                    </div>
                </div>
                <div className="course-questionare-footer">
                    <h2>{pointer + 1}/{questions.length}</h2>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                        {/* <Button onClick={() => onBackClick()} title="Back" style={{ width: '100px', backgroundColor: 'whitesmoke', color: 'gray', paddingTop: 10, paddingBottom: 10, borderRadius: '10px', marginRight: 10 }} /> */}
                        <Button onClick={() => onNextClick()} title={pointer === (questions.length - 1) ? "Finish" : "Next"} style={{ width: '100px', paddingTop: 10, paddingBottom: 10, borderRadius: '10px' }} />
                    </div>
                </div>
            </div>
            :
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                <img src={AppSvg.loading} alt="loading" style={{ width: '30px', height: '30px' }} />
            </div>
    )
}