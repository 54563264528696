import React, { useState, useEffect, useCallback, ReactNode, useRef } from 'react';

interface SelectionMenuProps {
  onTextSelect?: (text: string) => void;
  children?: ReactNode;
  menuContent?: ({ text }: { text: string }) => ReactNode;
}

const SelectionMenu: React.FC<SelectionMenuProps> = ({ onTextSelect, children, menuContent }) => {

  const containerRef = useRef<HTMLDivElement>(null);

  const [menuState, setMenuState] = useState<{
    text: string;
    position: { x: number; y: number } | null;
    isVisible?: boolean;
  }>({ text: '', position: null, isVisible: false });

  const isSelectionWithinContainer = useCallback((selection: Selection): boolean => {
    if (!containerRef.current || !selection.rangeCount) return false;

    const range = selection.getRangeAt(0);
    const container = containerRef.current;

    return container.contains(range.commonAncestorContainer);
  }, []);

  const calculatePosition = useCallback((range: Range) => {
    const rect = range.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    const scrollY = window.scrollY;

    // Menu dimensions
    const menuHeight = 40; // Approximate height
    const menuWidth = 350; // Approximate width
    const margin = 10;

    // Calculate available space on right
    const availableSpaceRight = viewportWidth - rect.right;

    let x;
    if (availableSpaceRight < menuWidth + 100 * 2) {
      // Near edge - move towards center
      x = viewportWidth - menuWidth - 100;
    } else {
      // Enough space - keep close to selection
      x = rect.right + margin;
    }

    // Ensure x is never negative
    x = Math.max(margin, x);

    // Calculate vertical position
    let y = rect.top + scrollY; // Start with selection top

    // Check if menu would go below viewport
    if (rect.top + menuHeight > viewportHeight - margin) {
      // Position menu above selection
      y = rect.top + scrollY - menuHeight - margin;
    } else {
      // Position menu below selection
      y = rect.bottom + scrollY + margin;
    }

    return { x, y };
  }, []);

  useEffect(() => {
    const handleSelection = () => {
      const selection = window.getSelection();

      if (!selection || !isSelectionWithinContainer(selection)) {
        setMenuState(prev => ({ ...prev, isVisible: false }));
        return;
      }

      const text = selection.toString();

      if (text) {
        const range = selection.getRangeAt(0);
        const position = calculatePosition(range);

        setMenuState({ text, position, isVisible: false });
        requestAnimationFrame(() => {
          setMenuState(prev => ({ ...prev, isVisible: true }));
        });
        onTextSelect?.(text);
      } else {
        setMenuState(prev => ({ ...prev, isVisible: false }));
        setTimeout(() => {
          setMenuState({ text: '', position: null, isVisible: false });
        }, 200);
      }
    };

    const handleClickOutside = (e: MouseEvent) => {
      if (!containerRef.current?.contains(e.target as Node)) {
        setMenuState(prev => ({ ...prev, isVisible: false }));
      }
    };

    document.addEventListener('mouseup', handleSelection);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleSelection);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calculatePosition, isSelectionWithinContainer, onTextSelect]);
  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      {menuState.text && menuState.position && (
        <div
          className="selection-menu"
          style={{
            position: 'absolute',
            left: `${menuState.position.x}px`,
            top: `${menuState.position.y - 100}px`,
            background: 'white',
            boxShadow: '0 2px 8px lightgray',
            borderRadius: '10px',
            zIndex: 1000,
            opacity: menuState.isVisible ? 1 : 0,
            transform: `translateY(${menuState.isVisible ? '0' : '10px'})`,
            transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out',
            pointerEvents: menuState.isVisible ? 'auto' : 'none',
          }}
          role="menu"
        >
          {
            menuContent ? menuContent({ text: menuState.text }) || menuState.text : menuState.text
          }
        </div>
      )}
      {children}
    </div>
  );
};

export default SelectionMenu;