import React from 'react';
import './index.css'; 

interface FileSizeWarningModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const FileSizeWarningModal: React.FC<FileSizeWarningModalProps> = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="file-size-modal-overlay">
            <div className="file-size-modal-content">
                <h2>File Size Warning</h2>
                <p>Your file exceeds the maximum size limit of 5MB. Please choose a smaller file.</p>
                <button className="file-size-modal-close-button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default FileSizeWarningModal;
