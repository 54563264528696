import { useSelector } from "react-redux";
import { selectUser, selectIsLoggedIn } from "./userSlice";
import { selectCourseBotContext } from "./coursebotSlice";
import { LocalUser } from "../utils/interface/localUser";

export function useUser(): LocalUser | null {
  return useSelector(selectUser);
}

export function useIsLoggedIn(): Boolean {
  return useSelector(selectIsLoggedIn);
}

export function useCourseBotContext(): string | null {
  return useSelector(selectCourseBotContext);
}
