import { useEffect, useState } from "react";
import { useAxios } from "../useAxios";
import errorHandler from "../../utils/common/errHandler";

export const useChatHistory = () => {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const axios = useAxios();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTrigger]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      await axios
        .get("/history")
        .then((res) => {
          if (res.data) {
            console.log("History API call succeeded:", res.data);
            setHistory(res.data);
          } else {
            setHistory([]);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          errorHandler("Chat History", error);
        });
    } catch (error) {
      setIsLoading(false);
      console.error("History API call failed:", error);
    }
  };

  const refreshHistory = () => setRefreshTrigger((prev) => prev + 1);

  return { data: history, refreshHistory, isLoading };
};
