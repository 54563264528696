import React, { useEffect } from "react";
import './styles.css';
import { AppSvg } from "../../../assets/svg";
import { Button } from "../../../components";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { ApiHead } from "../../../config/envConfig";
import errorHandler from "../../../utils/common/errHandler";
import LoadingModal from "../../../components/loading-modal";
import { useDispatch } from "react-redux";
import { setActiveUser } from "../../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import { LocalUser } from "../../../utils/interface/localUser";

export default function LoginScreen() {

  const auth = useAuth0()
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    initializeAuth();
   // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const initializeAuth = async () => {

    if (!auth) {
      alert("Auth0 is not initialized");
      return
    }

    setShowModal(true);
    await auth?.loginWithPopup()
      .then(async () => {
        const credentials = await auth.getIdTokenClaims();
        const token = credentials?.__raw;

        if (token) {
          await handleUserInfo(token, 'normal');
        } else {
          setShowModal(false);
          return
        }
      })
      .catch((err) => {
        alert("Error during login");
        setShowModal(false);


        console.error("Error during login:", err);
        return
      })
  };



  const handleUserInfo = async (token: string, provider: string) => {
    await axios.get(`${ApiHead}/auth/get-user/`, { headers: { "Authorization": `Bearer ${token}` } })
      .then(async (res) => {

        const userInformation = res.data
        if (!userInformation) {
          console.error("User information not found");
          setShowModal(false);
          alert("User information not found");
          return;
        }


        let userInfo = {
          id: userInformation?.id || "",
          email: userInformation?.email || "",
          firstName: userInformation?.first_name || "",
          lastName: userInformation?.last_name || "",
          isActive: userInformation?.is_active || false,
          profilePhoto: userInformation?.picture || "",
          mobileNo: userInformation?.mobile_no || "",
          profileBio: userInformation?.profile_bio || "",
          taskInterests: userInformation?.task_interests || "",
          preferredLanguage: userInformation?.preferredLanguage || "",
          dateOfBirth: userInformation?.date_of_birth || "",
          isPro: userInformation?.is_subscription_active || false,
          isProSelected: userInformation?.is_subscription_active ? true : false, 
          username: userInformation?.username || "",
          skills:userInformation?.skills || [],
          interests: userInformation?.interests || [],

          token,
        } as LocalUser



        if (userInfo?.isActive) {
          dispatch(setActiveUser(userInfo));
          navigate('/')

        } else {
          alert("User is not active");
        }


        setShowModal(false)


      })
      .catch((err) => {
        setShowModal(false);
        errorHandler('Login', err);
        return;
      })
  }

  const onPressLogin = async () => {
    await initializeAuth();
  }

  return (
    <div className="login-screen">
      {showModal && <LoadingModal modalVisible={showModal} />}

      <div className="wrapper">
        <img src={AppSvg.logo} className="login-logo" alt="logo" />

        <Button
          title="Login"
          onClick={onPressLogin}
        />
        <span>Please Allow Popup to login, if blocked !</span>
      </div>
    </div>
  )
}