import React, { CSSProperties, ReactNode } from "react";
import { LearnIcons } from "../../../assets/images";
import './styles.css'

interface CanvasProps {
    onClose: () => void;
    title: string;
    icon: string;
    children: ReactNode;
    titleStyle?: CSSProperties;
}


export default function Canvas(props: CanvasProps) {

    const onClose = () => {
        props.onClose();
    }

    return (
        <div className="easier-text-container">
            <div className="easier-text-title">
                <div className="easier-text-title-holder">
                    <img src={props.icon} alt="easier" />
                    <span
                        style={props.titleStyle}
                    >{props.title}</span>
                </div>
                <img src={LearnIcons.Cross} alt="close" onClick={onClose} />
            </div>
            <div className="easier-text-content-holder">
                {props.children}
            </div>
        </div>
    )
}