import React, { useState, KeyboardEvent, useRef } from 'react';

interface ChatInputProps {
  onSendMessage: (message: string, file?: File) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    if (message.trim()) {
      
      onSendMessage(message.trim(), selectedFile || undefined);
      setMessage('');
      setSelectedFile(null);
      setPreviewUrl('');
    }
  };

  const handleFileClick = (type: 'image' | 'audio') => {
    if (fileInputRef.current) {
      fileInputRef.current.accept = type === 'image' ? 'image/*' : 'audio/*';
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      
      // Create preview URL
      if (file.type.startsWith('image/')) {
        const url = URL.createObjectURL(file);
        setPreviewUrl(url);
      } else if (file.type.startsWith('audio/')) {
        setPreviewUrl('audio-file');
      }
    }
  };

  const removeFile = () => {
    setSelectedFile(null);
    setPreviewUrl('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const formatFileSize = (bytes: number): string => {
    if (bytes === 0) return '0 B';
    
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const size = parseFloat((bytes / Math.pow(k, i)).toFixed(2));
    
    return `${size} ${sizes[i]}`;
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && message.trim()) {
      handleSubmit();
    }
  };

  return (
    <div className="chat-input-wrapper">
      {selectedFile && (
        <div className="file-preview-container-bot">
          <div className="file-preview-content-bot">
            <div className="file-preview-left">
              {previewUrl && previewUrl !== 'audio-file' ? (
                <div className="image-preview-bot">
                  <img src={previewUrl} alt="Preview" />
                  <div className="preview-overlay">
                    <span className="preview-type">Image</span>
                  </div>
                </div>
              ) : (
                <div className="audio-preview-bot">
                  <div className="audio-icon-wrapper">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z" stroke="currentColor" strokeWidth="2"/>
                      <path d="M19 10v2a7 7 0 0 1-14 0v-2M12 19v4M8 23h8" stroke="currentColor" strokeWidth="2"/>
                    </svg>
                    <div className="preview-overlay">
                      <span className="preview-type">Audio</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            <div className="preview-details">
              <div className="file-info">
                <div className="file-name" title={selectedFile.name}>
                  {selectedFile.name}
                </div>
                <div className="file-meta">
                  <span className="file-size">
                    {formatFileSize(selectedFile.size)}
                  </span>
                  <span className="file-type">
                    {selectedFile.type.split('/')[1].toUpperCase()}
                  </span>
                </div>
              </div>
              <div className="upload-progress">
                <div className="progress-bar">
                  <div className="progress-fill"></div>
                </div>
                <span className="progress-text">Ready to send</span>
              </div>
            </div>
            
            <button className="remove-preview" onClick={removeFile} title="Remove file">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
        </div>
      )}

      <div className="chat-input-container">
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileSelect}
        />
        
        <div className="chat-upload-buttons">
          {/* Image upload button */}
          <button 
            className="chat-upload-button"
            onClick={() => handleFileClick('image')}
            title="Upload image"
          >
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
              <path 
                d="M4 16L8.586 11.414C9.367 10.633 10.633 10.633 11.414 11.414L16 16M14 14L15.586 12.414C16.367 11.633 17.633 11.633 18.414 12.414L20 14M14 8H14.01M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              />
            </svg>
          </button>

          {/* Audio upload button */}
          <button 
            className="chat-upload-button"
            onClick={() => handleFileClick('audio')}
            title="Upload audio"
          >
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
              <path 
                d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              />
              <path 
                d="M19 10v2a7 7 0 0 1-14 0v-2M12 19v4M8 23h8"
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>

        <input
          className="coursebot-chat-input"
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder={selectedFile ? "Add a description for your file..." : "Type a message..."}
        />
        
        <button 
          className={`chat-send-button ${!message.trim() ? 'disabled' : ''}`}
          onClick={handleSubmit}
          disabled={!message.trim()}
        >
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
            <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" fill="currentColor"/>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ChatInput;