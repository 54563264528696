import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    context: null,
};

const courseBotSlice = createSlice({
    name: "courseBot",
    initialState,
    reducers: {
        setCourseBotContext: (state, action) => {
            console.log('Setting context:', action.payload);
            state.context = action.payload;
        },
        clearCourseBotContext: (state) => {
            state.context = null;
        },
    },
});

export const { setCourseBotContext, clearCourseBotContext } = courseBotSlice.actions;

export const selectCourseBotContext = (state: any) => state.courseBot.context;

export default courseBotSlice.reducer;
