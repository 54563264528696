import React from "react";
import './styles.css'
import { Colors } from "../../../utils/constants/colors";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { AppSvg } from "../../../assets/svg";
import { BasicCourseDetail } from "../../../utils/interface/course";


interface CourseCardProps {
    course: BasicCourseDetail
    onCourseClick?: (course: any) => void
    index?: number
}

export default function CourseCard(props: CourseCardProps) {

    const course = props.course

    const onCourseClick = () => {
        if (props.onCourseClick) {
            props.onCourseClick(course)
        }
    }

    const progress = Math.round(course.completion_ratio || 0);



    const isImageAvialable = () => {
        if (course.image_url) {
            if (course.image_url !== 'None' && course.image_url !== 'NA') {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const getInitials = () => {
        const names = course.course_name.split(' ');
        if (names.length === 1) {
            return names[0].slice(0, 2);
        }
        return names.map(n => n[0]).join('');
    };

    return (
        <div className={"course-card"} onClick={onCourseClick}>
            <div className="course-card-image">{
                isImageAvialable() ?
                    <img src={course.image_url} alt="course" />
                    :
                    <div className="course-card-image-placeholder">
                        <img src={`https://api.dicebear.com/9.x/glass/png?backgroundType=gradientLinear,solid&seed=${course.course_name}`} style={{ width: '100%', height: '100%' }} alt="course" />
                        <p style={{ position: 'absolute', zIndex: 100, fontSize: '2rem', color: 'white', fontWeight: 'bold' }}>{getInitials()}</p>
                    </div>
            }

            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', width: '100%',flex:1 }}>
                <div className="course-card-body">
                    <h4>{course.course_name}</h4>
                    <p>Duration - {course.duration} hr</p>
                </div>
                <div style={{ width: 50, height: 50, marginRight: 10 }}>
                    <CircularProgressbarWithChildren value={progress}
                        styles={{
                            root: {},
                            path: {
                                stroke: Colors.primary,
                                strokeWidth: 5,
                            },
                        }} >
                        <img src={AppSvg.PlayFill} alt="play" style={{ width: 20, height: 20 }} />
                    </CircularProgressbarWithChildren>
                </div>


            </div>
            {
                course.is_enrolled &&
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', width: '100%', margin: 10 }}>
                    <span
                        className="enrolled-tag"
                    >Enrolled</span>
                </div>
            }

        </div>
    )
}