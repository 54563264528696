import Cross from "./cross.png";
import Star from "./star.jpg";
import Pro from "./pro.png";
import PreiumBanner from "./premium.png";
import SendIcon from "./send.png";
import UpgradeIcon from "./upgrade.png";
import BG from "./BG.png";
import AeroplaneIcon from "./aeroplane.png";
import AndroidIcon from "./android.png";
import DressIcon from "./dress.png";
import BotIcon from "./botLogo.png";
import ChankyaIcon from "./chankyaIcon.png";
import GenerationIcon from "./generation.png";
import AlertIcon from "./alert.png";
import StopStreamingIcon from "./stopStreamIcon.png";
import InfiniteIcon from "./infinite.png";
import ArrowDown from "./arrowDown.png";
import TickCircle from "./tickCircle.png";
import MicIcon from "./mic.png";
import HomeIcon from "./home.png";
import AppTag from "./appTag.png";
import newChat from "./newChat.png";
import webClose from "./webClose.png";
import plus from "./Plus.png";
import help from "./help.png";
import setting from "./settings.png";
import user from "./user.jpg";
import MagicAi from "./magicAi.png";
import AskBot from "./askBot.png";
import VoiceGuide from "./voiceGuide.png";
import PrevModule from "./prevModule.png";
import NextModule from "./nextModule.png";
import MagicAiPurple from "./magicAiPurple.png";
import Bookmark from "./bookmark.png";
import Puzzle from "./puzzle.png";
import Circle from "./circle.png";
import CorrectChecked from "./checked-correct.png"
import WrongChecked from "./checked-wrong.png"

export const DrawerIcon = {
  Cross,
  HomeIcon,
  newChat,
  webClose,
  plus,
  help,
  setting,
  user,
};

export const PlanIcon = {
  Star,
  Pro,
  PreiumBanner,
  InfiniteIcon,
};

export const InputIcons = {
  SendIcon,
  UpgradeIcon,
};

export const AppIcons = {
  BG,
  ChankyaIcon,
  ArrowDown,
  TickCircle,
  AppTag,
};

export const SuggestionIcons = {
  AeroplaneIcon,
  AndroidIcon,
  DressIcon,
};

export const ChatIcons = {
  BotIcon,
  GenerationIcon,
  AlertIcon,
  StopStreamingIcon,
  MicIcon,
};


export const LearnIcons = {
  MagicAi,
  AskBot,
  VoiceGuide,
  PrevModule,
  NextModule,
  MagicAiPurple,
  Bookmark,
  Puzzle,
  Circle,
  CorrectChecked,
  WrongChecked,
  Cross,
}