import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import "./styles.css";
import Markdown from "../markdownRenderer/index";
import { AppSvg } from '../../assets/svg';

interface MessageComponentProps {
  message: {
    isBot: boolean;
    text: string;
    attachments?: any[];
  };
  showIcon?: boolean;
  showTyping?: boolean;
  toggleFlagActionsheet: () => void;
  isLastMessage: boolean;
  isGenerating: boolean;
  isError: boolean;
}

export const MessageComponent: React.FC<MessageComponentProps> = ({
  message,
  showIcon,
  showTyping,
  toggleFlagActionsheet,
  isLastMessage,
  isGenerating,
  isError,
}) => {
  const { isBot, text, attachments } = message;
  const messageClass = isBot ? "message bot" : "message user";
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleSpeechToggle = () => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const utterance = new SpeechSynthesisUtterance(text);
      window.speechSynthesis.speak(utterance);
      setIsSpeaking(true);
      utterance.onend = () => setIsSpeaking(false);
    }
  };


  const renderAttachment = (attachment: any) => {
    if (attachment.type.toLowerCase().includes('image')) {
      return (
        <div className="attachment-image">
          {attachment?.token ? (
            <>
              {isLoading && (
                <div className="custom-spinner">
                  <div className="spinner"></div>
                </div>
              )}
              <img
                src={attachment.uri}
                alt="media"
                className='attachment-image'
                onLoad={() => setIsLoading(false)}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  fetch(attachment.uri, {
                    headers: {
                      'Authorization': `Bearer ${attachment.token}`
                    }
                  })
                    .then(response => response.blob())
                    .then(blob => {
                      target.src = URL.createObjectURL(blob);
                      setIsLoading(false);
                    })
                    .catch(error => {
                      console.error('Error loading image:', error);
                      setIsLoading(false);
                    });
                }}
                style={{ display: isLoading ? 'none' : 'block' }}
              />
            </>
          ) : (
            <img src={URL.createObjectURL(attachment)} alt="media" className='attachment-image'/>
          )}
        </div>
      );
    } else if (attachment.type.toLowerCase().includes('audio')) {
      return (
        <div className="audio-attachment" aria-label="User uploaded audio">
          {attachment?.token ? (
            <>
              {isLoading && (
                <div className="custom-spinner">
                  <div className="spinner"></div>
                </div>
              )}
              <audio 
                controls
                src={attachment.uri}
                onCanPlay={() => setIsLoading(false)}
                onError={(e) => {
                  fetch(attachment.uri, {
                    headers: {
                      'Authorization': `Bearer ${attachment.token}`
                    }
                  })
                    .then(response => response.blob())
                    .then(blob => {
                      const audioElement = e.target as HTMLAudioElement;
                      audioElement.src = URL.createObjectURL(blob);
                      setIsLoading(false);
                    })
                    .catch(error => {
                      console.error('Error loading audio:', error);
                      setIsLoading(false);
                    });
                }}
                style={{ display: isLoading ? 'none' : 'block' }}
              >
                Your browser does not support the audio element.
              </audio>
            </>
          ) :  <audio controls><source src={URL.createObjectURL(attachment)} type={attachment.type} /> </audio>}
        </div>
      );
    }
    return null;
  };

  if (!isBot) {
    return (
      <div className="user-message-container">

        {attachments && attachments.length > 0 && (
          <div className="attachments-container">
            {attachments.map((attachment, index) => (
              <div key={index} className="attachment-wrapper">
                {renderAttachment(attachment)}
              </div>
            ))}
          </div>
        )}
        <div className="user-message">
        <Markdown markdownText={text} />
        </div>
      </div>
    );
  }



  return (
    <div className={messageClass}>
      {showIcon && (
        <img
          src={AppSvg.ChanakyaLogoSmall}
          alt="icon"
          style={{ width: 30, height: 30 }}
        />
      )}
      <div className="message-content">
        <Markdown markdownText={text} />
        {isGenerating && isLastMessage && (
          <div className="generating-label">
            <span className="generating"></span>
            <span>Generating..</span>
          </div>
        )}
        {isLastMessage && !isGenerating && (
          <div className="btn-main">
            <div className="btn-box">
              <button 
                onClick={toggleFlagActionsheet} 
                className="bottom-btn"
                data-tooltip-id="flag-tooltip"
                data-tooltip-content="Feedback"
              >
                <img
                  src={AppSvg.flagIcon}
                  alt="Flag"
                  className="icon"
                />
              </button>
              <Tooltip id="flag-tooltip" />

              <button
                onClick={() => copyToClipboard(text)}
                className="bottom-btn"
                data-tooltip-id="copy-tooltip"
                data-tooltip-content={isCopied ? "Copied!" : "Copy to clipboard"}
              >
                <img
                  src={AppSvg.copyIcon}
                  alt="Copy"
                  className="icon"
                />
              </button>
              <Tooltip id="copy-tooltip" />

              <button
                onClick={handleSpeechToggle}
                className={`bottom-btn ${isSpeaking ? 'stop-playing-btn' : ''}`}
                data-tooltip-id="speech-tooltip"
                data-tooltip-content={isSpeaking ? "Stop speaking" : "Read Aloud"}
              >
                <img
                  src={isSpeaking ?  AppSvg.muteSpeaker : AppSvg.Speaker }
                  alt={isSpeaking ? "Mute" : "Speak"}
                  className="icon"
                />
              </button>
              <Tooltip id="speech-tooltip" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageComponent;
