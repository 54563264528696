import React, { useState, useRef, useEffect } from 'react';
import MessageList from './MessageList';
import ChatInput from './ChatInput';
import { LearnIcons, DrawerIcon } from '../../assets/images';
import { ApiCollection } from "../../config/envConfig";
import { startEventStream } from "../../utils/common/eventSource";
import { useAxios } from '../../hooks/useAxios';
import './chat.css';

interface Message {
  id: string;
  text: string;
  isBot: boolean;
  file?: {
    url: string;
    type: string;
    name: string;
  };
}

const ChatContainer: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const eventSourceRef = useRef<any | null>(null);
  const currentMessageIdRef = useRef<string | null>(null);
  const axios = useAxios();

  useEffect(() => {
    loadChatHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadChatHistory = async () => {
    try {
      setIsLoading(true);
      const urlObj = new URL(window.location.href);
      const enrollmentId = urlObj.searchParams.get("enrollmentId") ?? "";
      const moduleId = urlObj.searchParams.get("moduleId") ?? "";

      const response = await axios.get(
        ApiCollection.courseBotHistory(enrollmentId, moduleId)
      );

      if (response.data) {
        const formattedMessages = response.data.map((msg: any) => ({
          id: msg.id,
          text: msg.content,
          isBot: msg.role === "assistant"
        }));

        setMessages(formattedMessages);
      }
    } catch (error) {
      console.error("Error loading chat history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = () => {
    if (isOpen) {
      handleClose();
    } else {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsClosing(false);
    }, 300);
  };

  const handleSendMessage = async (text: string, file?: File) => {
    let fileData;
    if (file) {
      const blobUrl = URL.createObjectURL(file);
      fileData = {
        url: blobUrl,
        type: file.type,
        name: file.name
      };
      console.log("File data created:", fileData);
    }

    const userMessage: Message = {
      id: Date.now().toString(),
      text,
      isBot: false,
      file: fileData
    };
    
    console.log("Sending message with file:", userMessage);
    setMessages(prev => [...prev, userMessage]);
    setIsTyping(true);

    try {
      const urlObj = new URL(window.location.href);
      const enrollmentId = urlObj.searchParams.get("enrollmentId");
      const moduleId = urlObj.searchParams.get("moduleId");

      const root = localStorage.getItem('persist:root');
      const rootJSON = JSON.parse(root || '{}');
      const stateUser = JSON.parse(rootJSON.user || '{}');
      const token = stateUser?.user?.token;

      if (!token) {
        throw new Error("User not authenticated");
      }

      const formData = new FormData();
      formData.append('query', text);
      formData.append('enrollment_id', enrollmentId || '');
      formData.append('module_id', moduleId || '');
      
      if (file) {
        formData.append('file', file);
      }

      const eventSource = startEventStream(
        file ? "formdata" : "json",
        ApiCollection.chanakyaGuru.courseBot,
        token,
        file ? formData : JSON.stringify({ query: text, enrollment_id: enrollmentId, module_id: moduleId }),
        onMessage,
        onError
      );

      if (eventSource) {
        eventSourceRef.current = eventSource;
      }

    } catch (error) {
      console.error('Error sending message:', error);
      setIsTyping(false);
    }
  };

  const onMessage = (e: MessageEvent) => {
    if (e.data === "[DONE]") {
      currentMessageIdRef.current = null;
      onStopStreaming();
      return;
    }

    try {
      const payload = JSON.parse(e.data);

      if (payload?.choices && payload.choices.length > 0) {
        const botMessage = payload.choices[0].text;

        if (!currentMessageIdRef.current) {
          currentMessageIdRef.current = payload.id || Date.now().toString();
        }

        setMessages(prevMessages => {
          const newMessages = [...prevMessages];
          const messageId = currentMessageIdRef.current || Date.now().toString();
          const existingIndex = newMessages.findIndex(msg => msg.id === messageId);

          if (existingIndex === -1) {
            const newBotMessage: Message = {
              text: botMessage,
              isBot: true,
              id: messageId
            };
            return [...newMessages, newBotMessage];
          } else {
            newMessages[existingIndex].text = 
              (newMessages[existingIndex].text || '') +
              (botMessage || '');
            return newMessages;
          }
        });
      }
    } catch (error) {
      console.error("Failed to parse message data:", error);
    }
  };

  const onError = (error: any) => {
    console.error("Chat Error:", error);
    setIsTyping(false);

    let errorText = "❌ There was an error processing your request. Please try again.";
    try {
      errorText = JSON.parse(error.data).detail;
    } catch {
      // Use default error message
    }

    setMessages(prev => [...prev, {
      id: Date.now().toString(),
      text: errorText,
      isBot: true
    }]);

    onStopStreaming();
  };

  const onStopStreaming = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
    setIsTyping(false);
  };

  
  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  return (
    <div className="coursebot-container">
      {isOpen && (
        <div className={`coursebot-window ${isClosing ? 'closing' : ''}`}>
          <div className="coursebot-header">
            <div className="coursebot-title">
              <img src={LearnIcons.AskBot} alt="easier" className="bot-icon" />
              Ask Chanakya !
            </div>
            <button className="coursebot-close-button" onClick={handleClose}>
              <img src={DrawerIcon.Cross} alt="close-arrow-coursebot" style={{cursor: 'pointer', width: 24, height: 24}} />
            </button>
          </div>
          <div className="coursebot-content">
            {isLoading ? (
              <div className="coursebot-loader">
                <div className="loader-spinner"></div>
                <p>Loading chat history...</p>
              </div>
            ) : (
              <MessageList messages={messages} isTyping={isTyping} />
            )}
            <ChatInput onSendMessage={handleSendMessage} />
          </div>
        </div>
      )}
      
      <button 
        className="coursebot-toggle-button"
        onClick={handleToggle}
        style={{ display: !isOpen && !isClosing ? 'flex' : 'none' }}
      >
        <img src={LearnIcons.AskBot} alt="easier" className="bot-icon" />
        Ask Chanakya !
      </button>
    </div>
  );
};

export default ChatContainer;