import React from "react"
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";
import styles from './styles.module.css'
import Clipboard from 'react-clipboard.js';

export default function CodeRenderer(props: any) {


    const codeBlockMatch = props.code.match(/```(.*?)\n([\s\S]*?)```/);

    return (
        <div className={styles.codeTerminal}>
            <div className={styles.terminalHead}>
                <div className="head-dot-holder">
                    <svg stroke="currentColor" fill="#ff5f56" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="8"></circle>
                    </svg>
                    <svg stroke="currentColor" fill="#ffbd2e" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="8"></circle>
                    </svg>
                    <svg stroke="currentColor" fill="#27c93f" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="8"></circle>
                    </svg>
                </div>


                <Clipboard onClick={() => alert("Code Copied Successfully!")} className={styles.copyButton} data-clipboard-text={props.code}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                </Clipboard>
            </div>
            <SyntaxHighlighter
                style={atomOneDark}
                language={codeBlockMatch ? codeBlockMatch[1] : undefined}
                PreTag="div"
                customStyle={{
                    padding: '20px',
                }}
                wrapLines={true}
                wrapLongLines={true}
            >
                {codeBlockMatch ? codeBlockMatch[2] : props.code}
            </SyntaxHighlighter>

        </div>
    )
}