export const staticSuggestions = [
  {
    icon: "https://res.cloudinary.com/neurobridge/image/upload/v1717139258/androi_kp4q8j.png",
    prompt: "How should I study for upcoming exams?",
    value: "Mere exams start ho rahe hai me kaise padhu",
  },
  {
    icon: "https://res.cloudinary.com/neurobridge/image/upload/v1719572062/suggestion_gjta6q.png",
    prompt: "tell me your name under 100 letters",
    value: "UPSC Study plan",
  },
  {
    icon: "https://res.cloudinary.com/neurobridge/image/upload/v1719572062/suggestion_gjta6q.png",
    prompt: "tell me your name under 100 letters",
    value: "What is the grandfather paradox",
  },
];
