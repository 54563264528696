import React, { useEffect, useState } from "react";
import ChanakyaLearnHeader from "../../../../../components/learn/header";
import "react-circular-progressbar/dist/styles.css";
import { Colors } from "../../../../../utils/constants/colors";
import { Button } from "../../../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useAxios } from "../../../../../hooks/useAxios";
import { ApiCollection } from "../../../../../config/envConfig";
import { CourseDetail } from "../../../../../utils/interface/course";
import { AppSvg } from "../../../../../assets/svg";
import "./index.css";

export default function CourseCustomizeScreen() {
  useEffect(() => {
    getCourseDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id: courseId } = useParams();
  const axios = useAxios();
  const navigate = useNavigate();

  const [courseDetail, setCourseDetail] = React.useState<CourseDetail | null>(
    null
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedModules, setSelectedModules] = useState<{
    [key: string]: boolean;
  }>({});

  const getCourseDetail = async () => {
    setIsLoading(true);
    await axios
      .get(`${ApiCollection.chanakyaGuru.courseDetail}=${courseId}`)
      .then((res) => {
        // console.log("res", courseDetail);
        setCourseDetail(res.data);
        // console.log('is enrolled', res.data)
        // setIsEnrolled(res.data?.detail?.is_enrolled);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // Setting all modules as selected by default
    if (courseDetail?.modules) {
      const initialSelected: { [key: string]: boolean } = {};

      // Setting each module to selected (true)
      courseDetail.modules.forEach((module) => {
        initialSelected[module.id] = true;
      });

      setSelectedModules(initialSelected);
      console.log("Initial Selected Modules:", Object.keys(initialSelected));
      console.log("Initial Non-Selected Modules:", []);
    }
  }, [courseDetail]);

  const toggleModule = (moduleId: string) => {
    setSelectedModules((prev) => {
      // toggle kar raha hu  the selected state for this module
      const newState = {
        ...prev,
        [moduleId]: !prev[moduleId],
      };

      //  selected and non-selected module IDs hain
      let selected = [];
      let notSelected = [];

      // Sorting  kar raha hu  selected and non-selected
      for (let id in newState) {
        if (newState[id]) {
          selected.push(id);
        } else {
          notSelected.push(id);
        }
      }
      console.log("Selected Modules:", selected);
      console.log("Non-Selected Modules:", notSelected);

      return newState;
    });
  };

  const onStartPress = async (type: "recommended" | "custom") => {
    let modules: any = [];

    if (type === "recommended") {
      const allModuleIds =
        courseDetail?.modules.map((module) => module.id) || [];
      modules = allModuleIds;
    } else {
      const selectedModuleIds = Object.keys(selectedModules).filter(
        (moduleId) => selectedModules[moduleId]
      );
      modules = selectedModuleIds;
    }

    if (modules.length === 0) {
      alert("Please select atleast one module to proceed");
      return;
    }

    await axios
      .post(ApiCollection.chanakyaGuru.courseEnrollment, {
        course_id: courseId,
        course_module_ids: modules,
      })
      .then((res) => {
        const searchParams = new URLSearchParams({
          enrollmentId: res.data.enrollment_id || "",
          moduleId: modules.length > 0 ? modules[0] : "",
        }).toString();

        setIsLoading(false);
        navigate(`/learn/course/module?${searchParams}`, { replace: true });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        alert("Something went wrong, please try again later");
        navigate(-1);
      });
  };

  return !isLoading ? (
    courseDetail ? (
      <div className="customize-detail-screen">
        <ChanakyaLearnHeader />
        <div className="customize-detail-body">
          {/* HEADING */}

          {/* CONTENT */}
          <div className="customize-detail-content-holder">
            <div className="customize-detail-content">
              <h4 className="customize-catcher">Customize Your Course</h4>

              {courseDetail && courseDetail?.modules.length > 0 ? (
                courseDetail.modules.map((module, index) => {
                  return (
                    <div key={index} className="customize-detail-content-item">
                      <div className="module-header">
                        <div className="module-title-wrapper">
                          <div
                            className="module-toggle"
                            onClick={() => toggleModule(module.id)}
                          >
                            <img
                              src={
                                selectedModules[module.id]
                                  ? AppSvg.checkCircle
                                  : AppSvg.plusCircle
                              }
                              alt={
                                selectedModules[module.id]
                                  ? "Selected"
                                  : "Not Selected"
                              }
                              style={{ width: "20px", height: "20px" }}
                            />
                          </div>
                          <h4 style={{ fontSize: "1.1rem", margin: "8px 0" }}>
                            {module.module_name}
                          </h4>
                        </div>
                      </div>
                      <div className="module-content">
                        {Array.isArray(module.description) ? (
                          <ul>
                            {module.description.map((item, idx) => (
                              <li key={idx}>{item.data}</li>
                            ))}
                          </ul>
                        ) : (
                          <span>{module.description}</span>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="customize-detail-content-item">
                  <h3>No Modules Right Now !</h3>
                  <span>
                    Currently we dont have any module for this course, please
                    come back later to see the updated content. We keep updating
                    our courses to deliver you better !
                  </span>
                </div>
              )}
            </div>

            <div className="customize-detail-cta-holder">
              <h4 className="customize-catcher">Recommended Course</h4>

              <div className="customize-detail-cta">
                <li>Recommended Course</li>
                <span>
                  We have made this course by analyzing your past experience and
                  skills .
                </span>
              </div>
              <div className="customize-detail-cta">
                <li>Industry Standard Course</li>
                <span>
                  We make sure that we always deliver industry standard course
                  to help you acheive you your goals at fullest.
                </span>
              </div>
              {courseDetail?.modules.length > 0 && (
                <div style={{ width: "100%", marginTop: 60 }}>
                  <Button
                    onClick={() => onStartPress("custom")}
                    title="Save My Course"
                    style={{
                      padding: 10,
                      marginTop: 10,
                      backgroundColor: "white",
                      border: "solid",
                      borderColor: Colors.primary,
                      color: Colors.primary,
                      borderWidth: 1,
                    }}
                  />
                  <Button
                    title="Start Recommended"
                    style={{ padding: 10, marginTop: 5 }}
                    onClick={() => onStartPress("recommended")}
                  />
                </div>
              )}
              

                        
                          
                    
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div
        style={{
          minHeight: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <span style={{ marginBottom: 20 }}>No Course Found</span>
      </div>
    )
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <img
        src={AppSvg.loading}
        alt="loading"
        style={{ width: "30px", height: "30px" }}
      />
    </div>
  );
}
