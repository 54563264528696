import React from "react";
import "./index.css";
import { AppSvg } from "../../assets/svg";

interface ConversionLimitModalProps {
  user: {
    isPro: boolean;
  } | null;
  onClose: () => void;
  handlePremium: () => void;
}

const ConversionLimitModal: React.FC<ConversionLimitModalProps> = ({
  user,
  onClose,
  handlePremium,
}) => {

  
    return (
    <div className="conversation-modal-overlay">
      <div className="conversation-modal-content">
        <button className="close-button" onClick={onClose}>
        <img src={AppSvg.cross} alt="send" style={{ width: 25, height: 25 }} />
        </button>
        <img src={AppSvg.alert} alt="send" />
        <h2 className="title">Conversion Limit Reached!</h2>
        <p className="messageLimit">
           You've hit today's conversation limit. Please try again after some time.
        </p>
        {!user?.isPro ?  (
          <button className="action-button" onClick={handlePremium}>
            <strong>Upgrade Premium</strong> 
          </button>
        ) : ""}
      </div>
    </div>
  );
};

export default ConversionLimitModal;
