import React, { useEffect, useState } from "react";
import ChanakyaLearnHeader from "../../../../../components/learn/header";
import "react-circular-progressbar/dist/styles.css";
import { Colors } from "../../../../../utils/constants/colors";
import { Button } from "../../../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useAxios } from "../../../../../hooks/useAxios";
import { ApiCollection } from "../../../../../config/envConfig";
import { CourseDetail } from "../../../../../utils/interface/course";
import { AppSvg } from "../../../../../assets/svg";
import StartCourseModal from "../../../../../components/StartCourseModal/index";
import "./styles.css";

export default function CourseDetailScreen() {
  useEffect(() => {
    getCourseDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id: courseId } = useParams();
  const navigate = useNavigate();
  const axios = useAxios();

  const [courseDetail, setCourseDetail] = React.useState<CourseDetail | null>(
    null
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [expandedModules, setExpandedModules] = useState<number[]>([]);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getCourseDetail = async () => {
    setIsLoading(true);
    await axios
      .get(`${ApiCollection.chanakyaGuru.courseDetail}=${courseId}`)
      .then((res) => {
        console.log("res", courseDetail);
        setCourseDetail(res.data);
        console.log('is enrolled', res.data)
        setIsEnrolled(res.data?.detail?.is_enrolled);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onPersonalizeCourse = () => {
    const searchParams = new URLSearchParams({
      language: courseDetail?.course_metadata?.language || "Python",
      level: courseDetail?.course_metadata?.level || "Basic",
    }).toString();

    navigate(`/learn/course/detail/questionare?${searchParams}`);
  };

  const isImageAvialable = () => {
    if (courseDetail?.detail.image_url) {
      if (
        courseDetail?.detail.image_url !== "None" &&
        courseDetail?.detail.image_url !== "NA"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getInitials = () => {
    const names = courseDetail?.detail?.course_name.split(" ") || [];
    if (names.length === 1) {
      return names[0].slice(0, 2);
    }
    return names.map((n) => n[0]).join("");
  };

  const toggleModule = (index: number) => {
    setExpandedModules((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const onStartCourse = () => {
    if (isEnrolled) {

      const modules = courseDetail?.modules
      const thisModule = modules?.find((module) => module?.last_interaction === true)
      const index = modules?.findIndex((module) => module?.last_interaction === true)
      console.log('this module', thisModule, index)

      if (thisModule) {

        const searchParams = new URLSearchParams({
          enrollmentId: courseDetail?.detail.enrollment_id || '',
          moduleId: thisModule?.id || '',
        }).toString();
        navigate(`/learn/course/module?${searchParams}`);
      } else {
        alert('You have completed the course')
      }

    } else {
      setIsModalOpen(true);
    }

  };

  const onModuleClick = (moduleId: string) => {
    const searchParams = new URLSearchParams({
      enrollmentId: courseDetail?.detail.enrollment_id || '',
      moduleId: moduleId,
    }).toString();
    navigate(`/learn/course/module?${searchParams}`);
  }

  return !isLoading ? (
    courseDetail ? (
      <div className="course-detail-screen">
        <ChanakyaLearnHeader />
        <div className="course-detail-body">
          {/* HEADING */}
          <div className="course-detail-heading-holder-detail">
            <div className="course-detail-heading">
              {isImageAvialable() ? (
                <img
                  src={courseDetail.detail.image_url}
                  alt="course"
                  className="course-detail-image"
                />
              ) : (
                <div
                  style={{
                    width: 300,
                    height: 180,
                    borderRadius: 10,
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`https://api.dicebear.com/9.x/glass/png?backgroundType=gradientLinear,solid&seed=${courseDetail.detail.course_name}`}
                    style={{ width: "100%", height: "100%" }}
                    alt="course"
                  />
                  <p
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      fontSize: "2rem",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {getInitials()}
                  </p>
                </div>
              )}
              <div
                className="course-list-heading-holder"
                style={{

                  alignItems: "flex-start",
                  width: "40%",
                }}
              >
                <h2
                  style={{
                    marginLeft: 0,
                    marginBottom: 10,
                    fontSize: "1.3rem",
                  }}
                >
                  {courseDetail.detail.course_name}
                </h2>
                <span style={{ fontSize: "0.9rem" }}>
                  {courseDetail.detail.detail}
                </span>
              </div>
            </div>
            <div
              className="course-detail-progress"
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginRight: 70,
              }}
            >
              {/* <div style={{ width: 80, height: 80 }}>
                                    <CircularProgressbar value={66} text={`${66}%`}
                                        styles={{
                                            root: {},
                                            text: {
                                                fill: Colors.primary,
                                            },
                                            path: {
                                                stroke: Colors.primary,
                                            },
                                        }} />

                                </div>
                                <span style={{ fontSize: '0.9rem', marginTop: 10 }}>Progress</span> */}
            </div>
          </div>

          {/* CONTENT */}
          <div className="course-detail-content-holder">
            <div className="course-detail-content">
              <h4 className="catcher">What you will learn!</h4>

              {courseDetail && courseDetail?.modules.length > 0 ? (

                courseDetail?.modules.map((module, index) => {
                  return (
                    <div key={index} className="course-detail-content-item">
                      <div
                        className="module-header"
                        onClick={() => toggleModule(index)}
                      >
                        <div style={{ display: "flex", alignItems: "center", flexDirection: 'row', justifyContent: 'flex-start' }}>
                          {
                            module.is_completed && <img
                              src={AppSvg.checkCircle}
                              alt={"Completed"}
                              style={{ width: "20px", height: "20px" }}
                            />
                          }
                          <h4 style={{ marginLeft: 10, color: module.is_completed ? 'gray' : 'black' }}>{module.module_name}</h4>
                        </div>

                        <span
                          className={`expand-icon ${expandedModules.includes(index) ? "expanded" : ""
                            }`}
                        >
                          ▼
                        </span>
                      </div>
                      <div
                        className={`module-content ${expandedModules.includes(index) ? "expanded" : ""
                          }`}
                      >

                        {Array.isArray(module.description) ? (
                          <ul>
                            {module.description.map((item, idx) => (
                              <li key={idx}>{item.data}</li>
                            ))}
                            {
                              isEnrolled &&
                              <li style={{ cursor: 'pointer', fontWeight: 'bold', textDecoration: 'underline', color: Colors.primary, marginTop: 10 }} onClick={() => onModuleClick(module.id)}>
                                Study Now !
                              </li>
                            }

                          </ul>
                        ) : (
                          <span>{module.description}</span>
                        )}



                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="course-detail-content-item">
                  <h3>No Modules Right Now !</h3>
                  <span>
                    Currently we dont have any module for this course, please
                    come back later to see the updated content. We keep updating
                    our courses to deliver you better !
                  </span>
                </div>
              )}
            </div>

            <div className="course-detail-cta-holder">
              <h4 className="catcher">Made for You, by You !</h4>

              <div className="course-detail-cta">
                <li> Dynamic Learning</li>
                <span>
                  We offer totally dynamic courses which adapts to your learning
                  curve, made by you.sd
                </span>
              </div>
              <div className="course-detail-cta">
                <li>Paint your own perfect Course</li>
                <span>
                  You can edit modules, topics, explantion etc at any point of
                  time to your needs. It’s your course afterall !
                </span>
              </div>
              {courseDetail?.modules.length > 0 && (
                <div style={{ width: "100%", marginTop: 60 }}>
                  {!isEnrolled && (
                    <Button
                      onClick={onPersonalizeCourse}
                      title="Personalize Course"
                      style={{
                        padding: 10,
                        marginTop: 10,
                        backgroundColor: "white",
                        border: "solid",
                        borderColor: Colors.primary,
                        color: Colors.primary,
                        borderWidth: 1,
                      }}
                    />
                  )}
                  <Button
                    title={isEnrolled ? "Continue Course" : "Start Course"}
                    style={{ padding: 10, marginTop: 5 }}
                    onClick={onStartCourse}
                  />

                  <StartCourseModal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div
        style={{
          minHeight: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <span style={{ marginBottom: 20 }}>No Course Found</span>
      </div>
    )
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <img
        src={AppSvg.loading}
        alt="loading"
        style={{ width: "30px", height: "30px" }}
      />
    </div>
  );
}
