import React from 'react';
import { LearnIcons } from '../../assets/images'

const TypingIndicator: React.FC = () => {
  return (
    <div className="message bot">
      <div className="bot-avatar">
      <img src={LearnIcons.AskBot} alt="easier" className="bot-icon" />
      </div>
      <div className="message-content typing-indicator">
        <div className="typing-pulse">
          <div className="pulse-dot"></div>
          <div className="pulse-dot"></div>
          <div className="pulse-dot"></div>
        </div>
      </div>
    </div>
  );
};

export default TypingIndicator;
