import React from "react";
import { Colors } from "../../../utils/constants/colors";



export default function LanguagePicker(props: any) {

    const [selectedLanguage, setSelectedLanguage] = React.useState(props.selectedLanguage || 'en');

    const languages =
        [
            {
                "name": "Hindi",
                "id": "hi",
                "value": "Hindi"
            },
            {
                "name": "English",
                "id": "en",
                "value": "English"
            },
            {
                "name": "Kannada",
                "id": "kn",
                "value": "Kannada"
            },
            {
                "name": "Tamil",
                "id": "ta",
                "value": "Tamil"
            },
            {
                "name": "Gujrati",
                "id": "gu",
                "value": "Gujrati"
            },
            {
                "name": "Marathi",
                "id": "mr",
                "value": "Marathi"
            },
            {
                "name": "French",
                "id": "fr",
                "value": "French"
            },
            {
                "name": "Spanish",
                "id": "es",
                "value": "Spanish"
            },
            {
                "name": "German",
                "id": "de",
                "value": "German"
            }
        ]



    const onCloseModal = () => {
        if (props.onClose) {
            props.onClose();
        }
    }

    const onSelect = (lang: any) => {
        setSelectedLanguage(lang.value);
        if (props.onSelect) {
            props.onSelect(lang);
        }
    }

    return (
        <div
            onClick={onCloseModal}
            style={{
                display: "flex", flexDirection: "column", alignItems: "center",
                width: "100%", height: "100%", justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.5)", position: "fixed", top: 0, left: 0, zIndex: 1000,
            }}>
            <div
                onClick={(e) => e.stopPropagation()}
                style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "white", padding: 20, borderRadius: 10, maxWidth: '70%', minWidth: '50%' }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "white", padding: 20, paddingTop: 30 }}>
                    <span className="course-title">Select Language</span>
                    <h2 className="course-module-title">Voice guide can adapt to your preferences !</h2>
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'center', backgroundColor: "white", padding: 20, width: '70%', flexWrap: 'wrap' }}>
                    {
                        languages.map((lang: any) => (
                            <div key={lang.id}
                                onClick={() => onSelect(lang)}
                                style={{
                                    padding: 10, cursor: "pointer", margin: 10,
                                    borderRadius: 5, paddingRight: 10, paddingLeft: 10,
                                    border: selectedLanguage === lang.value ? '1px solid ' + Colors.primary : '1px solid #ccc',
                                    color: selectedLanguage === lang.value ? 'white' : 'black',
                                    backgroundColor: selectedLanguage === lang.value ? Colors.primary : 'white'
                                }}>
                                {lang.name}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}