import React, { useState, forwardRef, useRef } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import * as XLSX from "xlsx";

const MarkdownRenderer = (props) => {

  let markdownText = props.markdownText;
  let className = props.className;


  const [copiedCode, setCopiedCode] = useState(null);
  const tableRef = useRef(null);

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopiedCode(code);
      setTimeout(() => setCopiedCode(null), 2000);
    });
  };

  const handleExportToExcel = () => {
    if (!tableRef.current) return;

    try {
      const wb = XLSX.utils.table_to_book(tableRef.current);
      XLSX.writeFile(wb, "exported_table.xlsx");
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const ExportButton = () => (
    <button onClick={handleExportToExcel} style={styles.exportButton}>
      Export to Excel
    </button>
  );

  const CodeBlock = forwardRef(
    ({ node, inline, className, children, ...props }, ref) => {
      const match = /language-(\w+)/.exec(className || "");
      const language = match ? match[1] : "";

      if (inline) {
        return (
          <code className={className} {...props}>
            {children}
          </code>
        );
      }

      return (
        <div ref={ref} style={styles.codeBlockWrapper}>
          <button
            onClick={() => handleCopyCode(String(children).replace(/\n$/, ""))}
            style={styles.copyButton}
          >
            {copiedCode === String(children).replace(/\n$/, "")
              ? "Copied!"
              : "Copy"}
          </button>
          <SyntaxHighlighter
            style={dracula}
            language={language}
            PreTag="div"
            {...props}
            customStyle={styles.codeBlock}
            wrapLines={true}
            wrapLongLines={true}
          >
            {String(children).replace(/\n$/, "")}
          </SyntaxHighlighter>
        </div>
      );
    }
  );

  const Table = ({ children }) => (
    <div style={styles.tableContainer}>
      <div style={styles.exportButtonContainer}>
        <ExportButton />
      </div>
      <div className="table-wrapper">
        <table ref={tableRef} style={styles.table}>
          {children}
        </table>
      </div>
    </div>
  );

  return (
    <div style={styles.outerContainer}>
      <ReactMarkdown
        remarkPlugins={[gfm]}
        rehypePlugins={[rehypeRaw]}
        className={className}
        components={{
          code: CodeBlock,
          table: Table,
          thead: ({ children }) => (
            <thead style={styles.tableHead}>{children}</thead>
          ),
          th: ({ children }) => <th style={styles.tableHeader}>{children}</th>,
        }}
        skipHtml={false}
      >
        {markdownText}
      </ReactMarkdown>
    </div>
  );
};

const styles = {
  outerContainer: {
    width: "100%",
    maxWidth: "100vw",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
  },
  codeBlock: {
    maxWidth: "100%",
    fontSize: "14px",
    padding: "1em",
    borderRadius: "4px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  codeBlockWrapper: {
    position: "relative",
    maxWidth: "100%",
  },
  copyButton: {
    position: "absolute",
    right: "10px",
    top: "10px",
    padding: "5px 10px",
    background: "#444",
    color: "#fff",
    border: "none",
    borderRadius: "3px",
    cursor: "pointer",
  },
  tableContainer: {
    width: "100%",
    maxWidth: "800px",
    overflowX: "auto",
    position: "relative",
  },
  exportButtonContainer: {
    position: "sticky",
    top: "0",
    right: "0",
    zIndex: 1,
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    minWidth: "60%",
    maxWidth: "100%",
    border: "1px solid #ddd",
  },
  tableHead: {
    backgroundColor: "#f8f9fa",
  },
  tableHeader: {
    padding: "12px",
    borderBottom: "2px solid #ddd",
    fontWeight: "bold",
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  tableCell: {
    padding: "12px",
    borderBottom: "1px solid #ddd",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  exportButton: {
    padding: "5px 10px",
    background: "rgba(255, 74, 0, 1)",
    color: "#fff",
    border: "none",
    borderRadius: "3px",
    cursor: "pointer",
  },
};

export default MarkdownRenderer;
