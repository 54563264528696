import React from "react";


export default function NotFoundScreen() {

    return (
        <div className="App">
            <header className="App-header">

                <p>
                    404 Not Found
                </p>
            </header>
        </div>
    )
}