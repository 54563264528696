import React from 'react';
import './styles.css';
import { AppSvg } from '../../assets/svg';

interface LoadingModalProps {
    title?: string
    description?: string
    modalVisible: boolean
}

const LoadingModal = (props: LoadingModalProps) => {

    // const [visible, setVisible] = React.useState(props.modalVisible);

    return (
        <div className="backdrop">
            <div className="loadingModal">
                <img src={AppSvg.loading} alt="loading" style={{ width: '30px', height: '30px' }} />
                <h3 style={{ marginLeft: 15, fontWeight: 'normal' }} className='subTitle'>{props.title || 'Loading...'}</h3>
                {/* <p className='description'>{description}</p> */}
            </div>
        </div>

    );
};

export default LoadingModal;