import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import userReducer from "./userSlice";
import courseBotReducer from "./coursebotSlice";
import storage from "redux-persist/lib/storage";

const reducers = combineReducers({ user: userReducer, courseBot: courseBotReducer });
const persistConfig = {
  key: "root", storage: storage,
  // blacklist: ["courseBot"],
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export default store;