import React from 'react';
import { AppSvg } from '../../assets/svg';
import ReactMarkdown from 'react-markdown';

interface MessageProps {
  message: {
    id: string;
    text: string;
    isBot: boolean;
    file?: {
      url: string;
      type: string;
      name: string;
    };
  };
}

const Message: React.FC<MessageProps> = ({ message }) => {
  console.log("Rendering message:", message); // Debug log

  const renderFilePreview = () => {
    if (!message.file) {
      console.log("No file in message"); // Debug log
      return null;
    }

    console.log("Rendering file:", message.file); // Debug log

    if (message.file.type.startsWith('image/')) {
      return (
        <div className="message-file-preview">
          <img 
            src={message.file.url} 
            alt={message.file.name}
            className="message-image-preview"
            onError={(e) => console.error("Image failed to load:", e)} // Debug log
          />
          <div className="file-name">{message.file.name}</div>
        </div>
      );
    }

    if (message.file.type.startsWith('audio/')) {
      return (
        <div className="message-file-preview">
          <div>
            <audio controls>
              <source src={message.file.url} type={message.file.type} />
              Your browser does not support the audio element.
            </audio>
          </div>
          <div className="file-name">{message.file.name}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={`message ${message.isBot ? 'bot' : 'user'}`}>
      {message.isBot && (
        <div className="bot-avatar">
          <img src={AppSvg.ChanakyaLogoSmall} alt="Bot" />
        </div>
      )}
      <div className="message-content">
        {renderFilePreview()}
        {message.text && (
          message.isBot ? (
            <ReactMarkdown>{message.text}</ReactMarkdown>
          ) : (
            <div className="user-message-text">{message.text}</div>
          )
        )}
      </div>
    </div>
  );
};

export default Message;