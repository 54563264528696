import React, { useState, FormEvent } from 'react';
import './index.css';

const UserPersona: React.FC = () => {
  const [formValues, setFormValues] = useState({
    occupation: '',
    educationalBackground: '',
    industryExperience: '',
    technicalSkills: '',
    shortTermGoals: '',
    longTermGoals: '',
    resumeFile: null as File | null,
  });

  const [skills, setSkills] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newSkill, setNewSkill] = useState('');

  const commonSkills = ['JavaScript', 'Python', 'React', 'Node.js', 'SQL', 'Java', 'C++', 'AWS'];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormValues(prevValues => ({
        ...prevValues,
        resumeFile: e.target.files![0],
      }));
    }
  };

  const handleAddSkill = () => {
    if (newSkill && !skills.includes(newSkill)) {
      setSkills([...skills, newSkill]);
      setNewSkill('');
    }
  };

  const handleQuickAdd = (skill: string) => {
    if (!skills.includes(skill)) {
      setSkills([...skills, skill]);
    }
  };

  const handleRemoveSkill = (skillToRemove: string) => {
    setSkills(skills.filter(skill => skill !== skillToRemove));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Form values:', formValues);
  };

  return (
    <div className="container">
      <div className="form-card">
        <h1>Profile Summary</h1>
        <form onSubmit={handleSubmit}> 
          <div className="form-grid">
            <div className="form-group">
              <label htmlFor="occupation">Occupation</label>
              <input
                type="text"
                id="occupation"
                name="occupation"
                placeholder="Enter your occupation"
                value={formValues.occupation}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="educationalBackground">Educational</label>
              <select
                id="educationalBackground"
                name="educationalBackground"
                value={formValues.educationalBackground}
                onChange={handleInputChange}
              >
                <option value="">Select your education</option>
                <optgroup label="Undergraduate">
                  <option value="bachelor_cs">Bachelor's in Computer Science</option>
                  <option value="bachelor_engineering">Bachelor's in Engineering</option>
                  <option value="bachelor_business">Bachelor's in Business</option>
                  <option value="bachelor_arts">Bachelor's in Arts</option>
                </optgroup>
                <optgroup label="Graduate">
                  <option value="master_cs">Master's in Computer Science</option>
                  <option value="master_engineering">Master's in Engineering</option>
                  <option value="master_business">Master's in Business Administration (MBA)</option>
                  <option value="phd">Ph.D.</option>
                </optgroup>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="industryExperience">Industry Experience (years)</label>
              <input
                type="number"
                id="industryExperience"
                name="industryExperience"
                placeholder="Enter years of experience"
                value={formValues.industryExperience}
                onChange={handleInputChange}
                min="0"
              />
            </div>
            <div className="form-group">
              <label htmlFor="technicalSkills">Technical Skills</label>
              <div className="skills-container">
                {skills.map((skill, index) => (
                  <span key={index} className="skill-tag">
                    {skill}
                    <button 
                      type="button" 
                      className="remove-skill-btn" 
                      onClick={() => handleRemoveSkill(skill)}
                    >
                       ×
                    </button>
                  </span>
                ))}
                <button type="button" onClick={() => setIsModalOpen(true)} className="add-skill-btn">+</button>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="shortTermGoals">Short-term Goals</label>
              <textarea
                id="shortTermGoals"
                name="shortTermGoals"
                placeholder="What are your short-term goals?"
                value={formValues.shortTermGoals}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="longTermGoals">Long-term Goals</label>
              <textarea
                id="longTermGoals"
                name="longTermGoals"
                placeholder="What are your long-term goals?"
                value={formValues.longTermGoals}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="resumeFile">Upload Resume</label>
              <input
                type="file"
                id="resumeFile"
                name="resumeFile"
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx"
              />
            </div>
          </div>
          <div className="submit-button-container">
            <button className="submit-button" type="submit">Submit</button>
          </div>
        </form>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Add Skills</h2>
            <input
              type="text"
              value={newSkill}
              onChange={(e) => setNewSkill(e.target.value)}
              placeholder="Enter a skill"
            />
            <button onClick={handleAddSkill}>Add</button>
            <div className="quick-add">
              <h3>Quick Add</h3>
              <div className="common-skills">
                {commonSkills.map((skill) => (
                  <button key={skill} onClick={() => handleQuickAdd(skill)}>{skill}</button>
                ))}
              </div>
            </div>
            <button onClick={() => setIsModalOpen(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserPersona;
