import React from "react";



export default function ImageModal(props: any) {

    const url = props.url;

    const onCloseModal = () => {
        if(props.onClose) {
            props.onClose();
        }
    }

    return (
        <div
        onClick={onCloseModal}
        style={{
            display: "flex", flexDirection: "column", alignItems: "center",
            width: "100%", height: "100%", justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.5)", position: "fixed", top: 0, left: 0, zIndex: 1000,          
        }}>
            <img src={url} style={{ maxWidth: "80%", maxHeight: "80%" ,borderRadius:10}} alt="modal" />
        </div>
    );
}